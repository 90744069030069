import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth.reducer";
import dataReducer from "./data.reducer";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        data: dataReducer,
    },
});

export type AppStore = typeof store;
export type StoreRootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
