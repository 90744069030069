import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Workbook } from "../types/tableau-api";

type PerformanceData = Record<string, { ptp?: string; avp?: string }>;

export interface DataSlice {
    sidebar: Record<string, string>;
    workbooks: Workbook[];
    banner: { YTD: PerformanceData; MTD: PerformanceData };
    searchTerms: string;
    sidebarLoaded: boolean;
    workbooksLoaded: boolean;
    bannerLoaded: boolean;
}

export const dataSlice = createSlice({
    name: "data",
    initialState: (): DataSlice => ({
        sidebar: {},
        workbooks: [],
        banner: { YTD: {}, MTD: {} },
        searchTerms: typeof window !== "undefined" ? (window.sessionStorage.getItem("searchTerms") ?? "") : "",
        // Simple flags to indicate if the store has initialized the related data since application load.
        sidebarLoaded: false,
        workbooksLoaded: false,
        bannerLoaded: false,
    }),
    reducers: {
        setSidebar: (state, action: PayloadAction<DataSlice["sidebar"]>) => {
            state.sidebar = action.payload;
            state.sidebarLoaded = true;
        },
        setWorkbooks: (state, action: PayloadAction<DataSlice["workbooks"]>) => {
            state.workbooks = action.payload;
            state.workbooksLoaded = true;
        },
        setBanner: (state, action: PayloadAction<DataSlice["banner"]>) => {
            state.banner = action.payload;
            state.bannerLoaded = true;
        },
    },
});
export const { setSidebar, setWorkbooks, setBanner } = dataSlice.actions;
export default dataSlice.reducer;
