import React from "react";

export interface ISidebarContext {
    sidebarOpen: boolean;
    toggleSidebar: () => void;
}

export const SidebarContext = React.createContext<ISidebarContext>({
    sidebarOpen: false,
    toggleSidebar: () => {},
});

export const SidebarProvider: React.FC<React.PropsWithChildren> = (props) => {
    const [sidebarOpen, setSidebarOpen] = React.useState(() => {
        if (typeof window === "undefined") return false;
        return window.matchMedia("(min-width: 769px)").matches; // Bootstrap Medium ends at 768px.
    });

    const providerState: ISidebarContext = {
        sidebarOpen,
        toggleSidebar: () => setSidebarOpen(!sidebarOpen),
    };

    return <SidebarContext.Provider value={providerState}>{props.children}</SidebarContext.Provider>;
};
